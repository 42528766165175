<script setup lang="ts">
import { ref } from 'vue'

import { getClasses } from '~/js/composables/classes'

import { Analytics } from '~/js/interfaces/analytics'

import BadgeCounter from '~/vue/_atoms/badges/BadgeCounter.vue'

interface Props {
    badge?: number | null,
    content?: string,
    disabled?: boolean
    ga?: Analytics | null,
    icon?: string,
    iconPosition?: 'before' | 'after',
    type?: 'a' | 'button' | 'div' | 'submit',
    url?: string | null,
    utilities?: string
    utilitiesIcon?: string
    utilitiesContent?: string
}

const props = withDefaults(defineProps<Props>(), {
    content: '',
    disabled: false,
    iconPosition: 'before',
    size: 'default',
    type: 'button',
    url: null,
    utilities: '',
    utilitiesIcon: '',
    utilitiesContent: '',
})

const classes = ref({
    _default: 'flex justify-between items-center',
    font: 'font-primary font-bold',
    radius: 'rounded-sm rounded-br-2xl',
    spacing: 'py-2 px-4 gap-2 ',
    width: 'w-fit',
    utilities: props.utilities
})

const classesDisabled = ref({
    color: 'bg-yellow-200 text-purple-400',
})

const classesEnabled = ref({
    color: 'bg-yellow-600 text-purple-600',
    focus: 'focus:ring-2 focus:ring-blue-400 focus:ring-offset-1 focus:outline-none',
    hover: 'hover:bg-yellow-500',
})

const getType = () => {
    return props.type == 'submit' ? 'button' : props.type
}

</script>

<template>
    <component
        :is="getType()"
        :class="[
            getClasses(classes), 
            disabled ? getClasses(classesDisabled) : getClasses(classesEnabled),
            iconPosition == 'after' ? 'flex-row-reverse' : 'flex-row',
            utilities
        ]"
        :href="url"
        :title="type != 'div' ? ga?.label ?? content : false"
        :data-tag-category="type != 'div' ? ga?.category ?? '' : false"
        :data-tag-action="type != 'div' ? ga?.action ?? '' : false"
        :data-tag-label="type != 'div' ? ga?.label ?? '' : false"
        :type="type"
    >
        <span 
            :class="[
                'order-2 whitespace-nowrap flex items-center',
                utilitiesContent
            ]"
        >
            <span class="pt-1">{{ content }}</span>
            <BadgeCounter 
                v-if="badge && badge > 0"
                :label="badge" 
            />
        </span>

        <span 
            v-if="icon"
            :class="[
                'pt-1 text-xl',
                content.length > 0 ? (iconPosition == 'after' ? 'ml-1' : 'mr-1') : '',
                utilitiesIcon
            ]"
        >
            <i :class="icon"/>
        </span>
    </component>
</template>