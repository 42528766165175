<script setup lang="ts">
import { ref } from 'vue'

import { getClasses } from '~/js/composables/classes'

interface Props {
    color?: string,
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {
    color: 'text-purple-600',
    utilities: ''
})

const classes = ref({
    _default: {
        color: props.color,
        font: 'font-primary font-bold',
        size: 'text-2xl md:text-4xl',
        utilities: props.utilities,
    }
})
</script>

<template>
    <span :class="getClasses(classes, '_default')">
        <slot></slot>
    </span>
</template>